<template>
    <div class="modal">
        <div class="modal-inner modal-message">
            <div class="modal-title">{{ errorStatus ? 'Oops!' : loading ? 'Please Wait' : 'Success!' }}</div>
            <div class="modal-inner-content modal-message-content">
                {{ message }}
                <div v-if="loading" class="modal-icon"><font-awesome-icon icon="cog" class="spin" /></div>
                <div v-if="showIcon()" class="modal-icon"><font-awesome-icon :icon="showIcon()" /></div>
                <!-- <div v-else-if="errorStatus == 'invalid'" class="modal-icon"><font-awesome-icon icon="bell" /></div>
                <div v-else-if="errorStatus" class="modal-icon"><font-awesome-icon icon="bug" /></div> -->
            </div>
            <div class="modal-controls control-buttons modal-message-controls">
                <div class="button cancel" @click="dismissMessage()">Dismiss</div>
            </div>
        </div>
    </div>
</template>
<script>
// https://fontawesome.com/search?p=3&s=regular
export default {
    name: 'message',
    props: {
        message: {
            type: String,
            default: ''
        },
        loading: {
            type: [String, Boolean],
            default: false
        },
        errorStatus: {
            type: [String, Boolean],
            default: false
        }
    },
	methods: {
        dismissMessage() {
            this.$emit('dismiss')
        },
        showIcon() {
            switch (this.errorStatus) {
                case 'invalid':
                    return 'exclamation'
                case 'waiting':
                    return 'hourglass'
                case 'alert':
                    return 'bell'
                case 'error':
                    return 'bug';
                default:
                    return 'check'
            }
        }
    }
}
</script>
