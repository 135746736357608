<template>
<div v-if="paging.total_items > 0" class="pagination">
	<label>{{ paging.first_item }} - {{ paging.last_item }} of {{ paging.total_items }}</label>
	<div :class="['button','page-control', current_page > 1 ? 'prev' : 'disabled']" @click="goToPage('prev')">
		<svg width="8" height="10" xmlns="http://www.w3.org/2000/svg" version="1.1">
			<line x1="8" x2="1" y1="0" y2="5" stroke="white" stroke-width="2" stroke-linecap="butt"/>
			<line x1="1" x2="8" y1="5" y2="10" stroke="white" stroke-width="2" stroke-linecap="butt"/>
		</svg>
	</div>
	<input type="text" class="page-number" v-model="current_page" @change="goToPage('current_page')">
	<div :class="['button','page-control', current_page < paging.total_pages ? 'next' : 'disabled']" @click="goToPage('next')">
		<svg width="8" height="10" xmlns="http://www.w3.org/2000/svg" version="1.1">
			<line x1="0" x2="7" y1="0" y2="5" stroke="white" stroke-width="2" stroke-linecap="butt"/>
			<line x1="7" x2="0" y1="5" y2="10" stroke="white" stroke-width="2" stroke-linecap="butt"/>
		</svg>
	</div>
</div>
</template>

<script>
export default {
	name: 'pagination',
	props: ['paging'],
    data () {
        return {
	        current_page: 1
	    }
    },
    created() {
		this.current_page = this.paging.current_page || 1
		this.paging.first_item = this.paging.total_items > 0 ? ((this.current_page - 1) * this.paging.items_per_page) + 1 : 0
		this.paging.last_item = Math.min(this.paging.first_item + (this.paging.items_per_page - 1), this.paging.total_items)
		this.current_page = this.paging.current_page
	},
	watch: {
		paging: function(newSet,oldSet) {
			this.current_page = this.paging.current_page || 1
			this.paging.first_item = this.paging.total_items > 0 ? ((this.current_page - 1) * this.paging.items_per_page) + 1 : 0
			this.paging.last_item = Math.min(this.paging.first_item + (this.paging.items_per_page - 1), this.paging.total_items)
		}
	},
	methods: {
		goToPage(direction) {
			if (direction == 'current_page') {
				if (this.current_page < 1) { this.current_page = 1 }
				if (this.current_page > this.paging.total_pages) { this.current_page = this.paging.total_pages }
			} else {
				if (direction == 'prev' && (this.current_page - 1) < 1) return
				if (direction == 'next' && (this.current_page + 1) > this.paging.total_pages) return
				let step = (direction == 'prev') ? -1 : 1
				this.current_page = this.current_page + step
			}
			this.$emit('updatePage', this.current_page)
		}
	}
}
</script>
